import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux'
import { store } from './app/store'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ReactGA from "react-ga4";

import { Home } from './components/Home';
import { Splash } from './components/Splash';
import { APSplash } from './components/APSplash';
import { Consent } from './components/Consent';
import { Ineligible } from './components/Ineligible';
import { Draw } from './components/Draw';
import { Survey } from './features/survey/Survey';
import { SurveyAdmin } from './features/survey/SurveyAdmin';
import { ThankYou } from './components/ThankYou';
import { Credits } from './components/Credits';
import { Giveback } from './components/Giveback';
import { Zip } from './components/Zip';
import { APGiveback } from './components/APGiveback';
import { SurveyError } from './components/SurveyError';
import { GOOGLE_ANALYTICS_ID_PROD } from './constants';

import ErrorPage from './components/Error';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "ap",
        element: <APSplash />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "survey/:surveyid/:language?/:questionid?",
        element: <Survey />,
        errorElement: <ErrorPage />,
      },
      {
        path: "admin",
        element: <SurveyAdmin />,
      },
      {
        path: "error/:surveyid/",
        element: <SurveyError />,
      },
      {
        path: "data/:surveyid/:page?",
        element: <Giveback />,
      },
      {
        path: "participation/:surveyid/:page?",
        element: <APGiveback />,
      },
      {
        path: "draw",
        element: <Draw />,
      },
      {
        path: "/consent/:surveyid",
        element: <Consent />,
      },
      {
        path: "/zip",
        element: <Zip />,
      },
      {
        path: "exit/:surveytype",
        element: <Ineligible />,
      },
      {
        path: "closed/:surveytype",
        element: <Ineligible reason="closed"/>,
      },
      {
        path: "quota/:surveytype",
        element: <Ineligible reason="error"/>,
      },
      {
        path: "submitted/:surveyid/",
        element: <ThankYou />,
      },
      {
        path: "thankyou/:surveyid/",
        element: <ThankYou />,
      },
      {
        path: "credits",
        element: <Credits />,
      },
      {
        path: "",
        element: <Splash />,
      },
      
    ]
  },
]);

ReactGA.initialize(GOOGLE_ANALYTICS_ID_PROD);

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </CookiesProvider>
    </React.StrictMode>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// TODO(derek): set to register() for releases
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
