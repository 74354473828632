// used for dev builds - most URL paths are relative to host server
// TODO: fix use of REMOTE_HOST in service-worker
export const REMOTE_HOST = 'onop.tactilepictures.com';
// export const REMOTE_HOST = 'test.onopsurvey.arts.ufl.edu';
// export const REMOTE_HOST = 'onopsurvey.arts.ufl.edu';

// UUID to identify client app
export const CLIENT_ID = '2abfb99f-6cd2-4cc6-8f58-22a22bc36e6f';

export const QUALTRICS_BASE_PATH = 'https://yul1.qualtrics.com/API/v3/';

export const ONENATIONONEPROJECT_URL = 'https://onenationoneproject.com';

export const ONOP_ARTS_HEALTH_URL = 'https://www.onenationoneproject.com/arts-health-connection';

export const ONOP_INTEREST_SURVEY_URL = 'https://ufl.qualtrics.com/jfe/form/SV_1XFjQXJxhRDQ8OW';

export const BUG_REPORT_URL = 'https://forms.gle/FQEMayepNBSPb1MA8';

// for access during development, value doesn't have to be very secure
export const ACCESS_COOKIE_VALUE = 'iUDPmg9zM8K6B*hh';

// media query breakpoint for phone layout, width in pixels
export const PHONE_BREAKPOINT = 905;
export const DESKTOP_BREAKPOINT = 1205;
export const SMALL_DESKTOP_BREAKPOINT = 825; // for height

// zip code to use for testing
export const TEST_ZIP_CODE = '99990';

// default survey ID
// ToC Survey - App
// export const DEFAULT_SURVEY_ID = 'SV_6eZB95xzbYdxPsG';

// test snapshot - modified to work with SUBMIT_RESPONSES_BY_BLOCK
// Toc Survey - Generic - Snapshot 11-15-23
// export const DEFAULT_SURVEY_ID = 'SV_2gTK71igWkyBBEq';

// ToC Survey - Generic - Snapshot 2023-12-07
// export const DEFAULT_SURVEY_ID = 'SV_0x0k0ExFITunoOi';

export const DEFAULT_SURVEY_ID = 'SV_6eZB95xzbYdxPsG';

// ON/OP Arts Participation Survey - App
export const AP_SURVEY_ID = 'SV_9nLwrvytQwL70O2';

// ON/OP Arts Participation Survey - Snapshot 2023-12-07
// export const AP_SURVEY_ID = 'SV_bHJFVSONGYynDIG';

// ON/OP Arts Participation Survey - Snapshot 2023-12-18
// export const AP_SURVEY_ID = 'SV_82goB1FzE0tIcho';

// export const DEFAULT_SURVEY_ID = 'SV_dj6AhpcEHSk23VY';

export const GOOGLE_ANALYTICS_ID_DEV = 'G-402568B1W7';   // development
export const GOOGLE_ANALYTICS_ID_PROD = 'G-L16X7ZT53Q';

// feature flags
export const SUBMIT_RESPONSES_BY_BLOCK = true;
export const USE_SURVEY_CACHE = true;
export const REQUIRE_ACCESS_PASSWORD = false;
export const ADVANCE_ON_CHOICE_SELECTION = false;
