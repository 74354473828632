import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import {
    selectLocaleStrings,
    setCurrentZipCode
  } from '../features/survey/surveySlice';

import { Layout } from '../layouts/Layout';
import { ZipCode } from '../features/survey/questions/ZipCode';
import zipCodeData from '../data/ZipCodeData.json';
import { TEST_ZIP_CODE } from '../constants';

const zipCodeMap = zipCodeData as { [index: string]: string };

export function Zip() {
    const [validated, setValidated] = useState(false);
    const [surveyId, setSurveyId] = useState('');
    
    const navigate = useNavigate();
    const localeStrings = useAppSelector(selectLocaleStrings);
    const dispatch = useAppDispatch();

    function validate(zip: string) {
        // *********
        // While survey is closed, only allow test zip code to work
        /*
        if (zip !== TEST_ZIP_CODE) {
            navigate('/closed/toc');
            return;
        }
         */
        // *********
        
        // currently they all map to the same survey, but there may
        // be a need to have different survey projects in the future.
        // (this was the case originally).
        const surveyIdForZip = zipCodeMap[zip];
        if (surveyIdForZip) {
            setSurveyId(surveyIdForZip);
            setValidated(true);

            // save zip code to submit later
            dispatch(setCurrentZipCode(zip));
        }
        else {
            navigate(`/exit/toc`);
        }
    }

    function handleNext() {
        navigate(`/consent/${surveyId}`);
    }

    return (
        <Layout layoutClass="layout-intro">
            <ZipCode questionText={ localeStrings["zip"]} validatedText={ localeStrings["zipEligible"] }
                    buttonLabel={localeStrings['continue']}
                    validated={validated} onValidate={validate} onNext={handleNext} />
        </Layout>
    );
  }